<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink
              :destination="'/'"
              :navigation="false"
              :vegangLogo="true"
            >
            </buttonLink>
            <button-link
              :isInternal="true"
              v-if="!loggedIn"
              :content="' Sign-up as dropshipper'"
              :destination="'/register/retailer'"
              :filled="true"
            >
            </button-link>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-page for-retailer">
      <div class="authpage-header">
        <h1 class="title for-retailer">
          <span class="green-text">Hi, </span>Welcome back
          <span class="sub for-retailer">
            Did you forget your password? No worries! Send a request to reset
            you password.
          </span>
        </h1>
      </div>

      <div v-if="!emailIsSent" class="auth-box">
        <div class="errorMessage-container" v-if="showAuthError">
          <div class="material-icons error-icon">cancel</div>
          <div class="error-message">
            Ops! something happened
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-body">
          <div class="form">
            <CustomInput
              type="email"
              id="email"
              icon="email"
              label="Email address"
              v-model="login.email"
              :required="true"
            />
            <button
              class="vgang-button form-button"
              @click="handleForgotPwdRequest"
            >
              Send
            </button>
          </div>
        </div>
        <div class="box-footer">
          <span class="footer-text"
            >Do you want to be a dropshipper?
            <buttonLink
              :content="' Become a dropshipper'"
              :destination="'/login/retailer'"
              :greenText="true"
              :navigation="false"
            ></buttonLink>
          </span>
        </div>
      </div>
      <div v-else class="auth-box">
        <h2 class="form-title">
          {{ errorMessage }}
        </h2>
      </div>
      <img src="@/assets/img/retailer--bg.svg" alt="auth" class="auth-shape" />
    </div>
  </main>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
export default {
  metaInfo: {
    title: "vgang|forgot-password-retailer",
  },
  data() {
    return {
      showAuthError: false,
      errorMessage: "",
      emailIsSent: false,

      login: {
        email: "",
      },
    };
  },
  components: {
    CustomInput,
    buttonLink,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    isValidEmail(email) {
      // Regular expression for a simple email validation
      let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return emailPattern.test(email);
    },
    handleForgotPwdRequest() {
      this.message = "";
      this.submitted = true;
      let isValidEmail = this.isValidEmail(this.login.email);

      if (isValidEmail) {
        this.$store.dispatch("auth/requestForgotPassword", this.login).then(
          (data) => {
            this.errorMessage = data.message;
            this.emailIsSent = true;
          },
          (error) => {
            this.showAuthError = true;
            this.errorMessage = "Could not send email. Please try again later.";
            console.log(error);
          }
        );
      } else {
        this.showAuthError = true;
        !this.login.email
          ? (this.errorMessage = "Please enter your email.")
          : (this.errorMessage = "Please check your email!");
        setTimeout(() => {
          this.showAuthError = false;
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-size: 14px;
}
</style>
